dmx.Component('google-maps-marker', {

  attributes: {
    latitude: {
      type: Number,
      default: null,
    },

    longitude: {
      type: Number,
      default: null,
    },

    address: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    labelColor: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },

    info: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },

    image: {
      type: String,
      default: null,
    },

    animation: {
      type: String,
      default: null,
    },

    draggable: {
      type: Boolean,
      default: false,
    },
  },

  render: false,

  performUpdate (updatedProps) {
    if (updatedProps.has('latitude') || updatedProps.has('longitude')) {
      this._marker.setPosition({
        lat: +this.props.latitude,
        lng: +this.props.longitude,
      });
      this._marker.setVisible(true);
    }

    if (updatedProps.has('address')) {
      if (this.parent._geocodeCache[this.props.address]) {
        this._marker.setPosition(this.parent._geocodeCache[this.props.address]);
      }  else {
        this.parent._geocoder.geocode({ address: this.props.address }, (results, status) => {
          if (status == 'OK') {
            const pos = results[0].geometry.location
            this.parent._geocodeCache[this.props.address] = pos;
            this._marker.setPosition(pos);
            this._marker.setVisible(true);
            localStorage.geocodeCache = JSON.stringify(this.parent._geocodeCache);
          } else {
            console.warn(`Geocode was not successful for the following reason: ${status}`);
          }
        });
      }
    }

    if (updatedProps.has('label') || updatedProps.has('labelColor')) {
      this._marker.setLabel({
        color: this.props.labelColor,
        text: this.props.label,
      });
    }

    if (updatedProps.has('title')) {
      this._marker.setTitle(this.props.title);
    }

    if (updatedProps.has('info')) {
      this._marker.infoWindow = new google.maps.infoWindow({
        content: this.props.info,
      });
    }

    if (updatedProps.has('type')) {
      this._marker.setIcon(this.parent._markerTypes[this.props.type]);
    }

    if (updatedProps.has('image')) {
      this._marker.setIcon(this.props.image);
    }

    if (updatedProps.has('animation')) {
      this._marker.setAnimation(this.parent._getMarkerAnimation(this.props.animation));
    }

    if (updatedProps.has('draggable')) {
      this._marker.setDraggable(this.props.draggable);
    }
  },

});
