dmx.Component('google-maps', {

  initialData: {
    zoom: 10,
    maptype: 'roadmap',
    latitude: null,
    longitude: null,
  },

  attributes: {
    width: {
      type: [String, Number],
      default: '100%',
    },

    height: {
      type: [String, Number],
      default: 400,
    },

    latitude: {
      type: Number,
      default: null,
    },

    longitude: {
      type: Number,
      default: null,
    },

    address: {
      type: String,
      default: null,
    },

    zoom: {
      type: Number,
      default: 10,
    },

    maptype: {
      type: String,
      default: 'roadmap',
      enum: ['roadmap', 'satellite', 'hybrid', 'terrain'],
    },

    scrollwheel: {
      type: Boolean,
      default: false,
    },

    tilt: {
      type: Boolean,
      default: false,
    },

    rotateControl: {
      type: Boolean,
      default: false,
    },

    scaleControl: {
      type: Boolean,
      default: false,
    },

    fullscreenControl: {
      type: Boolean,
      default: false,
    },

    zoomControl: {
      type: Boolean,
      default: false,
    },

    streetviewControl: {
      type: Boolean,
      default: false,
    },

    maptypeControl: {
      type: Boolean,
      default: false,
    },

    enableClusters: {
      type: Boolean,
      default: false,
    },

    trafficLayer: {
      type: Boolean,
      default: false,
    },

    transitLayer: {
      type: Boolean,
      default: false,
    },

    bicyclingLayer: {
      type: Boolean,
      default: false,
    },

    markers: {
      type: Array,
      default: null,
    },

    markerId: {
      type: String, // expression
      default: 'id',
    },

    markerLatitude: {
      type: String, // expression
      default: 'latitude',
    },

    markerLongitude: {
      type: String, // expression
      default: 'longitude',
    },

    markerAddress: {
      type: String, // expression
      default: 'address',
    },

    markerLabel: {
      type: String, // expression
      default: 'label',
    },

    markerLabelColor: {
      type: String, // expression
      default: 'labelColor',
    },

    markerTitle: {
      type: String, // expression
      default: 'title',
    },

    markerInfo: {
      type: String, // expression
      default: 'info',
    },

    markerType: {
      type: String, // expression
      default: 'type',
    },

    markerImage: {
      type: String, // expression
      default: 'image',
    },

    markerAnimation: {
      type: String, // expression
      default: 'animation',
    },

    markerDraggable: {
      type: String, // expression
      default: 'draggable',
    },

    clusterGridSize: {
      type: Number,
      default: 60,
    },

    clusterMaxZoom: {
      type: Number,
      default: null,
    },

    minClusterSize: {
      type: Number,
      default: 2,
    },
  },

  methods: {
    addMarker (options) {
      const marker = this._addMarker(options);
      if (this._cluster && this.props.enableClusters) {
        this._cluster.addMarker(marker);
      } else {
        marker.setMap(this._map);
      }
    },

    goToMarker (id) {
      const marker = this._findMarker(id);
      if (marker) this._map.setCenter(marker.position);
    },

    panToMarker (id) {
      const marker = this._findMarker(id);
      if (marker) this._map.panTo(marker.position);
    },

    bounceMarker (id) {
      const marker = this._findMarker(id);
      if (marker) marker.setAnimation(1);
    },

    stopBounce (id) {
      const marker = this._findMarker(id);
      if (marker) marker.setAnimation(null);
    },

    showInfo (id) {
      const marker = this._findMarker(id);
      if (marker && marker.info) this._openInfoWindow(marker, marker.info);
    },

    fitBoundsToMarkers () {
      if (this._markers.length) {
        const bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < this._markers.length; i++) {
          bounds.extend(this._markers[i].getPosition());
        }

        this._map.fitBounds(bounds);
      }
    },

    removeAllMarkers () {
      this._removeAllMarkers();
    },

    panTo (lat, lng) {
      this._map.panTo({ lat: +lat, lng: +lng });
    },

    setCenter (lat, lng) {
      this._map.setCenter({ lat: +lat, lng: +lng });
    },

    setMapType (maptype) {
      this._map.setMapTypeId(maptype);
    },

    setZoom (zoom) {
      this._map.setZoom(zoom);
    },

    refresh () {
      google.maps.event.trigger(this._map, 'resize');
    },

    reload () {
      this._relaod();
    },
  },

  events: {
    ready: Event,
    boundschanged: Event,
    centerchanged: Event,
    maptypechanged: Event,
    zoomchanged: Event,
    mapclick: Event,
    markerclick: Event,
    markerpositionchanged: Event,
  },

  init () {
    const markerUrl = 'https://maps.google.com/mapfiles/';
    const iconsUrl = 'https://maps.google.com/intl/en_us/mapfiles/ms/micons/';

    this._geocodeCache = JSON.parse(localStorage.geocodeCache || '{}');
    this._geocoder = new google.maps.Geocoder();
    this._infoWindow = new google.maps.InfoWindow();

    this._markers = [];
    this._markerTypes = {
      black: markerUrl + 'marker_black.png',
      grey: markerUrl + 'marker_grey.png',
      orange: markerUrl + 'marker_orange.png',
      white: markerUrl + 'marker_white.png',
      yellow: markerUrl + 'marker_yellow.png',
      purple: markerUrl + 'marker_purple.png',
      green: markerUrl + 'marker_green.png',
      start: markerUrl + 'dd-start.png',
      end: markerUrl + 'dd-end.png',
      tree: iconsUrl + 'tree.png',
      lodging: iconsUrl + 'lodging.png',
      bar: iconsUrl + 'bar.png',
      restaurant: iconsUrl + 'restaurant.png',
      horsebackriding: iconsUrl + 'horsebackriding.png',
      convienancestore: iconsUrl + 'convienancestore.png',
      hiker: iconsUrl + 'hiker.png',
      swimming: iconsUrl + 'swimming.png',
      fishing: iconsUrl + 'fishing.png',
      golfer: iconsUrl + 'golfer.png',
      sportvenue: iconsUrl + 'sportvenue.png',
    };

    this._clickHandler = this._clickHandler.bind(this);
    this._boundsHandler = dmx.debounce(this._boundsHandler.bind(this), 100);
    this._centerHandler = dmx.debounce(this._centerHandler.bind(this), 100);
    this._maptypeHandler = dmx.debounce(this._maptypeHandler.bind(this), 100);
    this._zoomHandler = dmx.debounce(this._zoomHandler.bind(this), 100);
  },

  render (node) {
    this.$parse();

    node.style.setProperty('display', 'block');
    node.style.setProperty('width', this._getSize(this.props.width));
    node.style.setProperty('height', this._getSize(this.props.height));

    this._map = new google.maps.Map(node, {
      zoom: +this.props.zoom,
      center: { lat: +this.props.latitude, lng: +this.props.longitude },
      mapTypeId: this.props.maptype,
      scrollwheel: this.props.scrollwheel,
      scaleControl: this.props.scaleControl,
      zoomControl: this.props.zoomControl,
      panControl: this.props.panControl,
      streetViewControl: this.props.streetviewControl,
      mapTypeControl: this.props.maptypeControl,
      rotateControl: this.props.rotateControl,
      fullscreenControl: this.props.fullscreenControl,
    });

    if (window.googleMapsTheme) {
      this._map.setOptions({ styles: window.googleMapsTheme });
    }

    if (this.props.tilt) {
      this._map.setTilt(45);
    }

    this._getMarkers();

    if (this.props.enableClusters) {
      this._cluster = new MarkerClusterer(this._map, this._markers, {
        imagePath: this._getImageFolder(),
        gridSize: this.props.clusterGridSize,
        minimumClusterSize: this.props.minClusterSize,
        maxZoom: this.props.clusterMaxZoom,
      });
    }

    if (!(this.props.latitude && this.props.longitude) && this.props.address) {
      this._geocode(this.props.address);
    }

    if (this.props.trafficLayer) {
      this._trafficLayer = new google.maps.TrafficLayer();
      this._trafficLayer.setMap(this._map);
    }

    if (this.props.transitLayer) {
      this._transitLayer = new google.maps.TransitLayer();
      this._transitLayer.setMap(this._map);
    }

    if (this.props.bicyclingLayer) {
      this._bikeLayer = new google.maps.BicyclingLayer();
      this._bikeLayer.setMap(this._map);
    }

    this._map.addListener('click', this._clickHandler);
    this._map.addListener('bounds_changed', this._boundsHandler);
    this._map.addListener('center_changed', this._centerHandler);
    this._map.addListener('maptypeid_changed', this._maptypeHandler);
    this._map.addListener('zoom_changed', this._zoomHandler);

    this.set('latitude', +this.props.latitude);
    this.set('longitude', +this.props.longitude);
    this.set('maptype', this._map.getMapTypeId());
    this.set('zoom', this._map.getZoom());

    setTimeout(() => {
      this.dispatchEvent('ready');
    }, 100);
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('latitude') || updatedProps.has('longitude')) {
      this._map.setCenter({ lat: +this.props.latitude, lng: +this.props.longitude });
    }

    if (updatedProps.has('address')) {
      this._geocode(this.props.address);
    }

    if (updatedProps.has('zoom')) {
      this._map.setZoom(this.props.zoom);
    }

    if (updatedProps.has('maptype')) {
      this._map.setMapTypeId(this.props.maptype);
    }

    if (updatedProps.has('tilt')) {
      this._map.setTilt(this.props.tilt ? 45 : 0);
    }

    if (updatedProps.has('scrollwheel')) {
      this._map.setOptions({ scrollwheel: this.props.scrollwheel });
    }

    if (updatedProps.has('scaleControl')) {
      this._map.setOptions({ scaleControl: this.props.scaleControl });
    }

    if (updatedProps.has('zoomControl')) {
      this._map.setOptions({ zoomControl: this.props.zoomControl });
    }

    if (updatedProps.has('panControl')) {
      this._map.setOptions({ panControl: this.props.panControl });
    }

    if (updatedProps.has('streetviewControl')) {
      this._map.setOptions({ streetViewControl: this.props.streetviewControl });
    }

    if (updatedProps.has('maptypeControl')) {
      this._map.setOptions({ mapTypeControl: this.props.maptypeControl });
    }

    if (updatedProps.has('rotateControl')) {
      this._map.setOptions({ rotateControl: this.props.rotateControl });
    }

    if (updatedProps.has('fullscreenControl')) {
      this._map.setOptions({ fullscreenControl: this.props.fullscreenControl });
    }

    if (updatedProps.has('trafficLayer')) {
      this._trafficLayer = this._trafficLayer || new google.maps.TrafficLayer();
      this._trafficLayer.setMap(this.props.trafficLayer ? this._map : null);
    }

    if (updatedProps.has('trasitLayer')) {
      this._transitLayer = this._transitLayer || new google.maps.TransitLayer();
      this._transitLayer.setMap(this.props.transitLayer ? this._map : null);
    }

    if (updatedProps.has('bicycleLayer')) {
      this._bikeLayer = this._bikeLayer || new google.maps.BicyclingLayer();
      this._bikeLayer.setMap(this.props.bicyclingLayer ? this._map : null);
    }

    if (updatedProps.has('markers')) {
      this._removeAllMarkers();

      const markers = dmx.repeatItems(this.props.markers);

      if (Array.isArray(markers)) {
        for (let marker of markers) {
          const scope = dmx.DataScope(marker, this.parent);
          
          this._addMarker({
            id: dmx.parse(this.props.markerId, scope),
            latitude: +dmx.parse(this.props.markerLatitude, scope),
            longitude: +dmx.parse(this.props.markerLongitude, scope),
            address: dmx.parse(this.props.markerAddress, scope),
            label: dmx.parse(this.props.markerLabel, scope),
            labelColor: dmx.parse(this.props.markerLabelColor, scope),
            title: dmx.parse(this.props.markerTitle, scope),
            info: dmx.parse(this.props.markerInfo, scope),
            type: dmx.parse(this.props.markerType, scope),
            image: dmx.parse(this.props.markerImage, scope),
            animation: dmx.parse(this.props.markerAnimation, scope),
            draggable: !!dmx.parse(this.props.markerDraggable, scope),
          });
        }

        if (this.props.enableClusters) {
          this._cluster = new MarkerClusterer(this._map, this._markers, {
            imagePath: this._getImageFolder(),
            gridSize: this.props.clusterGridSize,
            minimumClusterSize: this.props.minClusterSize,
            maxZoom: this.props.clusterMaxZoom,
          });
        }
      }
    }
  },
  
  destroy () {
    // cleanup here
  },

  _reload () {
    this.performUpdate(new Map([
      ['latitude', 1],
      ['longitude', 1],
      ['address', 1],
      ['zoom', 1],
      ['maptype', 1],
      ['tilt', 1],
      ['scrollwheel', 1],
      ['scaleControl', 1],
      ['zoomControl', 1],
      ['panControl', 1],
      ['streetviewControl', 1],
      ['maptypeControl', 1],
      ['rotateControl', 1],
      ['fullscreenControl', 1],
      ['trafficLayer', 1],
      ['trasitLayer', 1],
      ['bicycleLayer', 1],
      ['markers', 1],
    ]));
  },

  _getSize (size) {
    if (typeof size == 'string' && size.slice(-1) == '%') {
      return size;
    } else {
      return parseInt(size, 10) + 'px';
    }
  },

  _getImageFolder () {
    const script = document.querySelector('script[src$="dmxGoogleMaps.js"]');
    if (script) return script.src.replace(/dmxGoogleMaps.js$/, 'images/m');
    return 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
  },

  _getMarkerAnimation (animation) {
    switch (animation.toLowerCase()) {
      case 'bounce': return 1;
      case 'drop': return 2;
    }

    return null;
  },

  _geocode (address) {
    if (address) {
      if (this._geocodeCache[address]) {
        this._map.setCenter(this._geocodeCache[address]);
      } else {
        this._geocoder.geocode({ address }, (results, status) => {
          if (status == 'OK') {
            this._geocodeCache[address] = results[0].geometry.location;
            this._map.setCenter(this._geocodeCache[address]);
            localStorage.geocodeCache = JSON.stringify(this._geocodeCache);
          } else {
            console.warn(`Geocode was not successful for the following reason: ${status}`);
          }
        });
      }
    }
  },

  _openInfoWindow (marker, content) {
    this._infoWindow.setContent(content);
    this._infoWindow.open(this._map, marker);
  },

  _getMarkers () {
    for (let child of this.children) {
      if (child instanceof dmx.Component('google-maps-marker')) {
        child._marker = this._addMarker({
          static: true,
          id: child.name,
          latitude: +child.props.latitude,
          longitude: +child.props.longitude,
          address: child.props.address,
          label: child.props.label,
          labelColor: child.props.labelColor,
          title: child.props.title,
          info: child.props.info,
          type: child.props.type,
          image: child.props.image,
          animations: child.props.animation,
          draggable: child.props.draggable,
        });
      }
    }
  },

  _findMarker (id) {
    return this._markers.find(marker => {
      return marker.id == id;
    });
  },

  _addMarker (options) {
    const marker = new google.maps.Marker({
      static: !!options.static,
      position: { lat: +options.latitude, lng: +options.longitude },
      label: options.label,
      title: options.title,
      icon: this._markerTypes[options.type],
      draggable: options.draggable,
    });

    if (options.id) {
      marker.id = options.id;
    }

    if (options.image) {
      marker.setIcon(options.image);
    }

    if (options.label && options.labelColor) {
      marker.setLabel({
        color: options.labelColor,
        text: options.label,
      });
    }

    if (options.info) {
      marker.info = options.info;
      marker.addListener('click', e => {
        this._openInfoWindow(marker, options.info);
      });
    }

    if (options.animation) {
      marker.setAnimation(this._getMarkerAnimation(options.animation));
    }

    if (!(options.latitude && options.longitude)) {
      if (options.address) {
        if (this._geocodeCache[options.address]) {
          marker.setPosition(this._geocodeCache[options.address]);
        } else {
          this._geocoder.geocode({ address: options.address }, (results, status) => {
            if (status == 'OK') {
              this._geocodeCache[options.address] = results[0].geometry.location;
              marker.setPosition(this._geocodeCache[options.address]);
              marker.setVisible(true);
              localStorage.geocodeCache = JSON.stringify(this._geocodeCache);
            } else {
              console.warn(`Geocode was not successful for the following reason: ${status}`);
            }
          });
        }
      } else {
        marker.setVisible(false);
      }
    }

    if (this._map && !this.props.enableClusters) {
      marker.setMap(this._map);
    }

    marker.addListener('click', this._markerClickHandler.bind(this, marker));
    marker.addListener('position_changed', this._markerPositionHandler.bind(this, marker));

    this._markers.push(marker);

    return marker;
  },

  _removeAllMarkers () {
    if (this._cluster) {
      this._cluster.clearMarkers();
    }

    this._markers = this._markers.filter(marker => {
      if (!marker.static) {
        google.maps.event.clearInstanceListeners(marker);
        marker.setMap(null);
        return false;
      }

      return true;
    })
  },

  _clickHandler (e) {
    this.dispatchEvent('mapclick', null, {
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
      position: e.latLng.toJSON(),
    });
  },

  _boundsHandler (e) {
    this.dispatchEvent('boundschanged');
  },

  _centerHandler (e) {
    const center = this._map.getCenter();
    this.set('latitude', center.lat());
    this.set('longitude', center.lng());
    this.dispatchEvent('centerchanged');
  },

  _maptypeHandler (e) {
    this.set('maptype', this._map.getMapTypeId());
    this.dispatchEvent('maptypechanged');
  },

  _zoomHandler (e) {
    this.set('zoom', this._map.getZoom());
    this.dispatchEvent('zoomchanged');
  },

  _markerClickHandler (marker) {
    this.dispatchEvent('markerclick', null, { id: marker.id })
  },

  _markerPositionHandler (marker) {
    this.dispatchEvent('markerpositionchanged', null, {
      id: marker.id,
      latitude: marker.position.lat(),
      longitude: marker.position.lng(),
      position: marker.position.toJSON(),
    });
  },

});
